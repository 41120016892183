<!--设备模块--设备运行时间表-->
<template>
  <div id="timetablemoduleRun">
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[0].dateDesc+" "+ weeks[0].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[0].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[0].day,weeks[0].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[1].dateDesc+" "+weeks[1].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[1].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[1].day, weeks[1].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[2].dateDesc+" "+weeks[2].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[2].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[2].day, weeks[2].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[3].dateDesc+" "+weeks[3].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[3].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[3].day, weeks[3].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[4].dateDesc+" "+weeks[4].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[4].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[4].day, weeks[4].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[5].dateDesc+" "+weeks[5].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[5].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[5].day, weeks[5].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ weeks[6].dateDesc+" "+weeks[6].dayDesc }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="weeks[6].day"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(weeks[6].day, weeks[6].dayDesc)"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>    
    <my-dialog
      :visible="visible"
      :data="dialogData"
      :name="data.name"
      :scheType="scheType"
      :week="week"
      :scheId="id"
      :calendarId="calendarId"
    ></my-dialog>
  </div>
</template>

<script>
import showTime from "./showTime";
import deviceScheduleDialog from "./deviceScheduleDialog";
import moment from 'moment';

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    scheType: {
      type: String,
    },
    id: {
      type: String,
    },
    calendarId:{
      type: Number,
    }
  },
  components: {
    "my-showtime": showTime,
    "my-dialog": deviceScheduleDialog,
  },
  data() {
    return {
      visible: false,
      dialogData: {},
      week: "",
      weekDay:[
        this.$t("doortimetable.a35"),
        this.$t("doortimetable.a29"),
        this.$t("doortimetable.a30"),
        this.$t("doortimetable.a31"),
        this.$t("doortimetable.a32"),
        this.$t("doortimetable.a33"),
        this.$t("doortimetable.a34"),
      ],
      weeks:[
        ],      
    };
  },
  created(){
    this.getWeeks();
  },
  watch: {
    pluginsDataChange (newval, oldval) {
        this.getDay();
    }
  },
  computed: {
    pluginsDataChange () {
        return JSON.stringify(this.data)
    }
  },
  methods: {
    getWeeks(){
      this.weeks.length=0;
      for(let i=0;i<7;i++){
        let days=moment().add(i ,'days').get('Days');
        let day ={
              days:days,
              dayDesc:this.weekDay[moment().add(i ,'days').get('Days')],
              dateDesc:moment().add(i ,'days').format('YYYY-MM-DD'),
          };
        this.weeks.push(day);
      }
      console.log("timetablemoduleRun weeks:",this.weeks);
    },
    getDay(){
      if(this.weeks==null||this.weeks.length==0)
        return;
      if(this.data==null||this.data.length==0)
        return;
        console.log("timetablemoduleRun data:",this.data);
      for(let i=0;i<7;i++){
        switch (this.weeks[i].days) {
          case 0:
            this.weeks[i].day= this.data.sunday;
            break;
          case 1:
            this.weeks[i].day= this.data.monday;
            break;
          case 2:
            this.weeks[i].day= this.data.tuesday;
            break;
          case 3:
            this.weeks[i].day= this.data.wednesday;
            break;
          case 4:
            this.weeks[i].day= this.data.thursday;
            break;
          case 5:
            this.weeks[i].day= this.data.friday;
            break;
          case 6:
            this.weeks[i].day= this.data.saturday;
            break;
          default:
            break;
        }
      }
    },
    // 改变对话框状态的方法
    closeDialog() {
      this.visible = false;
    },
    // 加载页面数据的方法
    getData() {
      this.$emit("getData");
    },
    // 点击修改icon时加载对应的数据和修改对话框状态
    getTime(data, week) {
      this.visible = true;
      this.dialogData=data;
      this.week = week;
      console.log("getTime data:",data);
    },
  },
  
};
</script>

<style>
#timetablemoduleRun {
  width: 100%;
}
.timetablemodule-row {
  width: 100%;
  height: 90px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #fff;
  box-shadow: 0 0px 5px #bdbcbc;
  border-radius: 3px;
}
.timetablemodule-row-one {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-right: 1px solid #e7e8e8;
}
.timetablemodule-row-two {
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
  padding: 0px 30px;
}
.timetablemodule-row-three {
  width: 70px;
  display: flex;
  align-items: center;
}
</style>