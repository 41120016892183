<!--设备模块--设备时间表-->
<template>
  <div id="lightRunTimetable">
    <!--侧边栏-时间树列表-->
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder :list="list" @select="getMsgFormSon"></my-navder>
      </a-spin>
    </div>
    <!--主内容，时间列表-->
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">        
        <div class="main">
          <!--标题-->
          <my-headertitle>{{$t('lightRunTimetable.a1')}}</my-headertitle>
          <div class="info">
            <!--名称修改-->
            <div>
              <span>{{$t('lightRunTimetable.a2')}}</span>
              <span v-show="initial">{{ name }}</span>
              <a-input
                v-show="alter"
                v-model="name"
                ref="input"
                class="info-input"
                size="small"
              />
              <a-icon
                type="form"
                v-show="initial"
                @click="StateChanges"
                :style="{
                  fontSize: '15px',
                  color: '#7682CE',
                  'margin-left': '15px',
                }"
              />
              <a-button
                v-show="alter"
                class="info-button"
                size="small"
                @click="amendCancel"
                type="primary"
                ghost
                >{{$t('lightRunTimetable.a3')}}</a-button
              >
              <a-button
                v-show="alter"
                class="info-button"
                size="small"
                type="primary"
                :loading="btloaing"
                @click="updateName"
                >{{$t('lightRunTimetable.a4')}}</a-button
              >
            </div>            
          </div>
          <!--具体时间表显示与修改-->
          <my-timetable
            :data="data"
            :scheType="scheType"
            :id="scheId"
            :calendarId="calendarId"
            @getData="getData"
          ></my-timetable>
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{$t('lightRunTimetable.a10')}}</span>
      </div>
      <!--页脚-时间表复制按钮-->
      <div class="footer" v-if="id">
        <a-button type="primary" @click="allCopyTt">{{$t('lightRunTimetable.a11')}}</a-button>
        <a-button type="primary" @click="copyTt">{{$t('lightRunTimetable.a12')}}</a-button>
      </div>
    </div>
    <a-modal
      :title="$t('lightRunTimetable.a13')"
      :width="400"
      centered
      :visible="visible1"
      :closable="true"
      :maskClosable="false"
      :confirm-loading="confirmLoading1"
      @ok="allHandleOk"
      @cancel="allHandleCancel"
    >
      <div class="modal1">
        <span>{{$t('lightRunTimetable.a14')}}</span>
      </div>
      <a-select
        :placeholder="this.$t('lightRunTimetable.a10')"
        v-model="modalKey1"
        style="width: 150px"
      >
        <a-select-option v-for="item in modal1" :key="item.key">
          {{ item.title }}
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      :title="$t('lightRunTimetable.a12')"
      :width="600"
      centered
      :visible="visible2"
      :closable="true"
      :maskClosable="false"
      :confirm-loading="confirmLoading2"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <span>{{$t('lightRunTimetable.a15')}}</span>
        <a-select
          :placeholder="$t('lightRunTimetable.a10')"
          v-model="srcDay"
          style="width: 150px; margin: 0px 20px"
        >
          <a-select-option v-for="item in week" :key="item.index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>{{$t('lightRunTimetable.a16')}}</span>
        <a-select
          :placeholder="$t('lightRunTimetable.a10')"
          v-model="beginDay"
          style="width: 150px; margin: 10px 20px"
        >
          <a-select-option v-for="item in week" :key="item.index">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <span>~</span>
        <a-select
          :placeholder="$t('lightRunTimetable.a10')"
          v-model="endDay"
          style="width: 150px; margin: 10px 20px"
        >
          <a-select-option v-for="item in week" :key="item.index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import Navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import timetableModuleRun from "../common/schedule/timetableModuleRun";
import { lightTimetableListKT,getLightTimetableData,updateScheduleName,getScheduleInfoDetail,copyAllSchedule,copyOneSchedule } from "../../api/device";
import {
  // getDID,
  doorTtUpdateName,
  // copyAllTt,
  // copyOneTt,
} from "../../api/door";
import moment from 'moment';

export default {
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": Navdar,
    "my-headertitle": headerTitle,
    "my-timetable": timetableModuleRun,
  },
  data() {
    return {
      calendarId: 0,
      name: "",
      spinning: false,
      list: [],
      alter: false,
      initial: true,
      id: undefined,
      loading: false,
      btloaing: false,
      data: undefined,
      initialname: "",
      scheId: undefined,
      scheType: "15",
      visible1: false,
      visible2: false,
      confirmLoading1: false,
      confirmLoading2: false,
      modal1: [],
      modal2: [],
      modalKey1: undefined,
      modalKey2: undefined,
      srcDay: undefined,
      beginDay: undefined,
      endDay: undefined,
      weekDay:[
        this.$t("lightRunTimetable.a23"),
        this.$t("lightRunTimetable.a17"),
        this.$t("lightRunTimetable.a18"),
        this.$t("lightRunTimetable.a19"),
        this.$t("lightRunTimetable.a20"),
        this.$t("lightRunTimetable.a21"),
        this.$t("lightRunTimetable.a22"),        
      ],
      week:[
        ],   
      // week: [
      //   { name: this.$t('lightRunTimetable.a17'), value: 0 },
      //   { name: this.$t('lightRunTimetable.a18'), value: 1 },
      //   { name: this.$t('lightRunTimetable.a19'), value: 2 },
      //   { name: this.$t('lightRunTimetable.a20'), value: 3 },
      //   { name: this.$t('lightRunTimetable.a21'), value: 4 },
      //   { name: this.$t('lightRunTimetable.a22'), value: 5 },
      //   { name: this.$t('lightRunTimetable.a23'), value: 6 },
      // ],
    };
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      this.loading = true;
      let number = this.id.split(",");
      this.scheId = number[1];
      this.getData();
      // let par = {
      //   scheId: number[1],
      //   scheType: number[0],
      // };
      // console.log("lightRunTimetable Par:",par);
      // getDID(par)
      //   .then((res) => {
      //     this.loading = false;
      //     let { data } = res;
      //     this.data = data;
      //     this.name = data.name;
      //     console.log("lightRunTimetable data:",this.data);
      //   })
      //   .catch((err) => {
      //     this.loading = false;
      //     console.log(err);
      //   });
    },
  },
  mounted() {
  },
  activated(){
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();   
    this.getWeeks();
    if(this.scheId && this.scheType){
      this.getData();
    }

  },
  methods: {    
    getWeeks(){
      this.week.length=0;
      for(let i=0;i<7;i++){
        let day ={
              index:i,
              name:this.weekDay[moment().add(i ,'days').get('Days')],
              value: (parseInt(moment().add(i ,'days').format("e"))+1)%7,
          };
        console.log("lightRunTimetable day:",day);
        this.week.push(day);
      }
      console.log("lightRunTimetable week:",this.week);
    },
    // 加载页面数据的方法
    getData() {
      this.loading = true;
      let data = {
        scheId: this.scheId,
        scheType: this.scheType,
      };
      getScheduleInfoDetail(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.data = data;
          this.name = data.name;
          console.log("lightRunTimetable getScheduleInfoDetail:",this.data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 名称修改框焦点获取
    StateChanges() {
      this.initialname = this.name;
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.name = this.initialname;
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
    // 获取页面左侧列表方法
    navderList() {
      let name = this.$route.name;
      // console.log("lightRunTimetable route name:", name);
      if(name.indexOf('FAC_RUN_SCEDULE') >= 0 || name.indexOf('ENE_RUN_SCEDULE') >= 0){
          this.scheType = "15"; 
      }
      // if(name === "FAC_RUN_SCEDULE" || name === "FACILITIES_FAC_RUN_SCEDULE" || name === 'ENVIRONMENT_FAC_RUN_SCEDULE')
      //   this.scheType="15";
      // else if(name=="FAC_KIKIRENDOU_GR")
      //   this.scheType="6";

       if(!this.scheType){
        return;
      }
      this.spinning = true;
      this.list = [];

      let data = {
        siteId: this.$route.query.id,
        schType: this.scheType,
      };
      lightTimetableListKT(data)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          this.list.push(data);
          console.log("lightRunTimetable Data:",data);
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
    },
    // 页面更新name成功后更新类表名字
    alterName(arr) {
      let id = this.id;
      let name = this.name;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key == id) {
          arr[i].title = name;
        }
        if (arr[i].children) {
          this.alterName(arr[i].children);
        }
      }
    },
    // 获取当前页的时间表列表
    getTtList(arr) {
      let id = this.scheId;
      
      if(arr!=null && arr.length>0){
        let children = arr[0].children;
        console.log("lightRunTimetable getTtList par:",children);
        if(children!=null && children.length>0){
          for (let i = 0; i < children.length; i++) {
            if (children[i].key !== undefined) {
              let _key = children[i].key.split(",")[0];
              let _id = children[i].key.split(",")[1];
              if (_id !== id) {
                let key = children[i].key.split(",")[1];
                let title = children[i].title;
                this.modal1.push({ key, title });
              }
            }
          }
        }
      }
    },
    // 更新name
    updateName() {
      this.btloaing = true;
      let scheId = this.scheId;
      let scheType = this.scheType;
      let newName = this.name;
      let data = {
        scheId,
        scheType,
        newName,
      };
      // console.log(data);
      doorTtUpdateName(data)
        .then((res) => {
          this.btloaing = false;
          // console.log(res);
          if (res.errorCode == "00") {
            this.initial = !this.initial;
            this.alter = !this.alter;
            this.alterName(this.list);
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
            this.amendCancel();
          }
        })
        .catch((err) => {
          this.btloaing = false;
          this.amendCancel();
          console.log(err);
        });
    },
    // 时间表复制(整表复制)
    allCopyTt() {
      this.visible1 = true;
      this.modal1 = [];
      this.modalKey1 = undefined;
      if(this.list!=null && this.list.length>0)
        this.getTtList(this.list[0].children);
    },
    // 时间表复制(整表复制)确定按钮
    allHandleOk() {
      this.confirmLoading1 = true;
      let srcId = this.modalKey1;
      let targetId = this.scheId;
      let scheType = this.scheType;
      let data = {
        srcId,
        targetId,
        scheType,
      };
      console.log("lightRunTimetable allHandleOk param",data);
      copyAllSchedule(data)
        .then((res) => {
          console.log("lightRunTimetable allHandleOk res",res);
          if (res.errorCode == "00") {
            this.confirmLoading1 = false;
            this.visible1 = false;
            this.getData();
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.confirmLoading1 = false;
            this.$message.error(res.msg);
          }
         
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // // 时间表复制(整表复制)取消按钮
    allHandleCancel() {
      this.visible1 = false;
    },
    // 时间表复制(表内复制)
    copyTt() {
      this.visible2 = true;
      this.getWeeks();
    },
    // 时间表复制(表内复制)确定按钮
    handleOk() {
      this.confirmLoading2 = true;
      let _b = this.beginDay;
      let _e = this.endDay;
      if (_b < _e || _b == _e) {
        let data = {
          schId: this.scheId,
          srcDay: this.srcDay,
          beginDay: this.beginDay,
          endDay: this.endDay,
          scheType: this.scheType,
        };
        console.log("lightRunTimetable handleOk param",data);
        copyOneSchedule(data)
          .then((res) => {
            console.log("lightRunTimetable handleOk res",res);
            if (res.errorCode == "00") {
              this.confirmLoading2 = false;
              this.handleCancel();
              this.getData();
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.confirmLoading2 = false;
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$error({
          title: this.$t('lightRunTimetable.a30'),
          centered: true,
          onOk: () => {},
        });
      }
    },
    // 时间表复制(表内复制)取消按钮
    handleCancel() {
      this.visible2 = false;
      this.srcDay = undefined;
      this.beginDay = undefined;
      this.endDay = undefined;
    },
  },
  
};
</script>
<style scoped>
#lightRunTimetable {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  /* width: 120px; */
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
</style>